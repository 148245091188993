@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900);
body {
    font-family: Roboto, "Helvetica Neue", Helvetica, Arial;
    font-size: 0.8125rem;
    letter-spacing: 0.1px;
}
.page-content {
    color: #666;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.3;
    font-weight: 400;
}
strong {
    font-weight: 500;
}
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small,
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
    font-weight: 300;
    display: block;
    font-size: 0.9375rem;
    line-height: 1.5;
    margin: 2px 0 1.5rem;
}
.h2 small,
.h3 small,
h2 small,
h3 small {
    font-size: 0.9375rem;
}
.h4 small,
h4 small {
    font-size: 0.875rem;
}
.h5 small,
.h6 small,
h5 small,
h6 small {
    font-size: 0.8125rem;
}
.text-contrast {
    color: #333;
}
.text-gradient {
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(25%, #6e4e9e),
        color-stop(50%, #62468d),
        color-stop(75%, #0c7cd5),
        to(#0960a5)
    );
    background: linear-gradient(180deg, #6e4e9e 25%, #62468d 50%, #0c7cd5 75%, #0960a5 100%);
    color: #886ab5;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: none;
}
body,
html {
    height: 100%;
    width: 100%;
}
#root {
    height: 100%;
}
.login-page {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    min-height: 100%;
    height: 100%;
    background: url(../images/pattern-1.svg) no-repeat center bottom fixed; background-size: cover; background-color: #072552;
}
html body .blankpage-logo {
    padding: 20px 0 30px;
}
html body .blankpage-form-field {
    width: 320px;
}
html body .blankpage-form-field .card {
    margin: 0;
    padding: 13.5px 20px 15.5px;
    -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}
html body .blankpage-form-field .blankpage-btn {
    font-size: 1rem;
    font-weight: 400;
    padding: 10px 15px;
    margin: 10px 0 5px;
}
html body .blankpage-footer {
    padding: 10px 0;
    font-size: 0.75rem;
    color: #73579d;
}
html body .login-footer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    color: rgba(255, 255, 255, 0.9);
    font-size: 0.6875rem;
    text-align: center;
}
html body video {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    background: url(../images/pattern-1.svg) no-repeat center bottom fixed; background-size: cover; background-color: #072552;
    background-size: cover;
    -webkit-transition: 1s opacity;
    transition: 1s opacity;
}
@media screen and (max-device-width: 800px) {
    html body html {
        background: url(../images/pattern-1.svg) no-repeat center bottom fixed; background-size: cover; background-color: #072552;
      
    }
    html body #bgvid {
        display: none;
    }
}
